<template>
	<div class="FM_page-accountOther">
		<div class="container" v-if="!formStatus.showSuccess">
			<div class="row">
				<div class="col">
					<ValidationObserver v-slot="{ handleSubmit }">
						<form class="FM_form-send" autocomplete="off" @submit.prevent="handleSubmit(submit)">
							<h3 class="FM_title-default">{{ $t('__changeEmail') }}</h3>
							<p class="text-light text-center">{{ $t('__changeEmailDesc1') }}</p>
							<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
								{{ formStatus.errorMsg }}
							</div>
							<div class="form-group">
								<ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
									<input type="text" v-model="form.email" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner" :placeholder="$t('__newEmail')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<div class="FM_btn-group">
								<button type="submit" class="btn btn-block FM_btn btn-success font-weight-bold" v-if="!formStatus.verified" :disabled="formStatus.spinner" data-toggle="modal">
									<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="formStatus.spinner"></span>
									{{ $t('__verifyEmail') }}
								</button>
							</div>
						</form>
					</ValidationObserver>
				</div>
			</div>
		</div>
		<div class="container" v-if="formStatus.showSuccess">
			<div class="row">
				<div class="col text-center">
					<h3 class="FM_title-default">{{ $t('__changeEmailSuccessSubtitle') }}</h3>
					<p class="text-light">
						{{ $t('__changeEmailSuccessContent') }}
					</p>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { setStorage, getStorage } from "@/utils/storage.js"
import { mapActions } from 'vuex'
import { urlReplace } from "@/utils/index.js"
import { extend } from 'vee-validate'
import { required, email, min, confirmed } from 'vee-validate/dist/rules'
import CN from 'vee-validate/dist/locale/zh_CN.json'
import { localize, validate } from 'vee-validate'
import Footer from '@/components/share/Footer.vue'

export default {
	name: "ChangeEmail",
	data(){
		return{
			form: {
				email: ''
			},
			formStatus: {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not registered
				showSuccess: null,
				errorMsg: ''
			}
		}
	},
	created() {
		if (getStorage('lang') === 'cn') {
			localize('zh_CN', CN);
		}
		extend('email', email);
		extend('required', required);

		this.getStatus();
	},
	methods: {
		...mapActions(['logout']),
		getStatus() {
			this.$Progress.start();
			this.formStatus.spinner = true;

			Ajax(
				API.accountStatus.method,
				API.accountStatus.url
			).then(result => {
				this.formStatus.spinner = false;
				this.formStatus.success = result.success;
				this.$Progress.finish();

				if(result.success !== 1) {
					this.formStatus.errorMsg = result.error_msg;
					return;
				}

				if(!result.data.data.login) {
					this.logout();
					return;
				}

				this.form.email = result.data.info.email;
			});
		},
		submit() {
			this.$Progress.start();
			this.formStatus.spinner = true;

			let _data = {
				account: this.form.email
			};

			Ajax(
				API.verifyPassword.method,
				API.verifyPassword.url,
				_data
			).then(async(result) => {
				this.formStatus.spinner = false;
				this.formStatus.showSuccess = result.success;
				this.$Progress.finish();

				if(result.success !== 1) {

					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
					return ;
				}

			})
		}
	},
	components: {
		Footer
	}
}
</script>

<style scoped>

</style>